/* eslint-disable */
export class LanguageKeys {
  public WELCOME_HEADLINE = "Welcome to the app";

  public MESSAGES = ({ count }: { count: number }) => {
    return count === 1 ? `${count} Message` : `${count} Messages`;
  };

  public MAIN_TITLE = "Revolutional/Aircrafts/Vertical Takeoff";
  public MAIN_DESCRIPTION = "Step forward in the delivery chain";
  public MAIN_UNIQUE = "Unique";
  public MAIN_SAFETY = "Safety";
  public MAIN_ALLOWED = "Allowed";
  public MAIN_FUTURE = "Future";
  public MAIN_TICKER =
    "It is capable of taking off from any ground, while 25 meters of runway is enough. It uses automobile gasoline and an electric motor powered by solar panels. Easy to control, takeoff and landing. It is stable even with a wind of 15 m/s (a conventional aircraft can withstand 7 m/s). Fully vertical takeoff and landing. High speed and flight range. The ability to move on the ground at a speed of 60 km / h. It can be used as a cargo, passenger or business class liner. The loading hatch rises, opening direct access to the cabin.";
  public PAGE2_TYPES = "4 types of new generation aircraft";
  public PAGE2_TYPES_MOBILE = "4 types aircrafts";
  public PAGE2_TYPE_1 = "Light aviation: small five-seat aircraft";
  public PAGE2_TYPE_2 =
    "Passenger and cargo transportation: A 30-seat airplane to replace helicopters and partially superjets";

  public PAGE2_TYPE_3 = "Freight transport over long distances: an innovation in the field of cargo transportation";
  public PAGE2_TYPE_4 = "Lasar - the world's first air cruise ship";

  public PAGE3_TITLE = "For construction, it is necessary";
  public PAGE3_STR_1 = "Establishment of a factory for the production of our developed enclosure material";
  public PAGE3_STR_2 = "Launch of the production line for spare parts and component materials";
  public PAGE3_STR_3 =
    "Construction of assembly halls for three models and a special area for the construction of the fourth model";
  public PAGE3_STR_4 = "500.000.000 $";
  public PAGE3_STR_5 = "Budget for full";
  public PAGE3_STR_6 = "implementation of the project";
  public PAGE3_STR_7 = "10.000.000 $";
  public PAGE3_STR_8 = "Budget for";
  public PAGE3_STR_9 = "the construction of the component plant and the";
  public PAGE3_STR_10 = "launch";
  public PAGE3_STR_11 = "of the first line";

  public PAGE4_STR_1 = "Project timeline";
  public PAGE4_STR_2 = "6 months";
  public PAGE4_STR_3 = "Plant construction";
  public PAGE4_STR_4 = "1 - 1.5 months";
  public PAGE4_STR_5 = "Model Assembly";
  public PAGE4_STR_6 = "2 - 4 months";
  public PAGE4_STR_7 = "Model Assembly";
  public PAGE4_STR_8 = "3 - 6 months";
  public PAGE4_STR_9 = "Model Assembly";

  public PAGE4_STR_10 = "Tranche 1";
  public PAGE4_STR_11 = "$10 million before production starts, immediately after the contract is signed";
  public PAGE4_STR_12 = "Tranche 2";
  public PAGE4_STR_13 = "$50 million after completion of plant, production line and assembly of Models 1 and 2";
  public PAGE4_STR_14 = "Tranche 3";
  public PAGE4_STR_15 = "$150 million after the assembly of model number 3";
  public PAGE4_STR_16 = "Tranche 4";
  public PAGE4_STR_17 = "$400 million before construction of the airliner began";
  public PAGE4_STR_18 = "Tranche 5";
  public PAGE4_STR_19 =
    "390 million after commissioning models 1,2 and 3 for construction (40) sample 1 model (24) sample 2 model (8) sample 3 model";

  public PAGE6_STR_1 = "Produced 5 aircraft and flight tests of the first model";
  public PAGE6_STR_2 = "Models 2 and 3 created in the layout and tested";
  public PAGE7_STR_1 = "Creation of new generation aircraft will completely change global logistics chains";
  public PAGE7_STR_2 = "Revolutionary changes will affect both freight and passenger transportation";
  public PAGE7_STR_3 = "Production owners will be able to control the freight system";
  public PAGE7_STR_4 =
    "The company will profit from the sale of Model 1 and 2, as well as from the trucking of Model 3 and 4 (they will not be sold on the free market).";

  public PAGE8_STR_1 = "We are raising $500 million for<br/>" + "the construction of a production facility and the production of 78 MP-1, MP-2, MP-3 aircrafts.";
  public PAGE8_STR_2 = "How much can you earn on this";
  public PAGE8_STR_3 = "<h3><strong>The cost of MP-1: $320.000</strong><h3/>" +
    "<h3><strong>Market price of MP-1: $750.000</strong></h3>" +
    "<h3><strong>Profitability per year: $20.640.000</strong></h3>" +
    "<h3><strong>from 4 production lines - 48 pcs per year</strong></h3></br>" +
    "" +
    "<h3><strong>The cost of MP-2: $1.050.000</strong><h3/>" +
    "<h3><strong>Market price of MP-2: $4.500.000</strong></h3>" +
    "<h3><strong>Profitability per year: $82.800.000</strong></h3>" +
    "<h3><strong>from 4 production lines - 24 pcs per year</strong></h3></br></br>";

  public PAGE8_STR_4 = "<h3><strong>The cost of MP-3 - $11.000.000</strong><h3/>" +
    "<h3><strong>from 2 production lines - 6 pcs per year</strong></h3>" +
    "</br></br>";


  public PAGE8_STR_6 = "Operating income from the rental of 6 pcs per year:";
  public PAGE8_STR_7 = "$216.000.000";
  public PAGE8_STR_8 = "The total profitability of the project per year:";
  public PAGE8_STR_9 = "$319.000.000";

  public PAGE8_STR_10 = "In connection with the rental operation of MP-3, profitability increases annually<br/>" +
    "by $216,000,000 and will amount to $535 million in the second year.<br/>" +
    "<br/>" +
    "It is important to consider that it can take about two years to create production lines and obtain licenses, so the full-fledged income from the project should be expected in the third year.<br/>" +
    "<br/>" +
    "MP-1 has already passed flight tests. The license for it will be obtained within 6-12 months.<br/><br/>";
  public PAGE8_STR_11 = "Road map";
  public PAGE8_STR_12 = "<strong>In 6 months</strong><br/>" +
    "Creation of a plant for the production of raw materials for the manufacture of hulls<br/>" +
    "and elements of aircraft<br/>" +
    "<br/>" +
    "<strong>After 12 months</strong><br/>" +
    "Creation of 5 production lines of MP-1, MP-2 and MP-3<br/>" +
    "<br/>" +
    "<strong>After 1.5 years</strong><br/>" +
    "Creation of the first 12 MP-1 models, 6 MP-2 models and 2 MP-3 models<br/>" +
    "<br/>" +
    "<strong>After 2 years</strong><br/>" +
    "Creation of 5 more production lines of MP-1, MP-2 and MP-3<br/>" +
    "Achieving the level of creation of 24 MP-1 models, 12 MP-2 models and 6 MP-3 models<br/>" +
    "<br/>" +
    "<strong>Within 1-2 years</strong><br/>" +
    "Obtaining licenses and conducting flight tests.<br/>";

  public PAGE_CHARACTERS_STR_1_MODEL1 = "MP-1 airplane without aerodrome landing";
  public PAGE_CHARACTERS_STR_2_MODEL1 = "Flying altitude 4000m";
  public PAGE_CHARACTERS_STR_3_MODEL1 = "Speed: 300km/h";
  public PAGE_CHARACTERS_STR_4_MODEL1 = "Crew: 1";
  public PAGE_CHARACTERS_STR_5_MODEL1 = "Passengers: 4";
  public PAGE_CHARACTERS_STR_6_MODEL1 = "Flying range: 1500km";
  public PAGE_CHARACTERS_STR_7_MODEL1 = "Takeoff weight: 1400t";
  public PAGE_CHARACTERS_STR_8_MODEL1 = "Load capacity: 450kg";
  public PAGE_CHARACTERS_STR_9_MODEL1 =
    "It is able to take off from any ground, and 25 meters of runway is enough. Uses car gasoline and solar-powered electric motor.";
  public PAGE_CHARACTERS_STR_9_1_MODEL1 =
    "Easy to control, take off and land. Stable even in winds of 15 m/s (normal aircraft can withstand 7 m/s)";
  public PAGE_CHARACTERS_STR_10_MODEL1 = "Production cost";
  public PAGE_CHARACTERS_STR_11_MODEL1 = "750.000 $";

  public PAGE_CHARACTERS_STR_1_MODEL2 = "Aerodrome-less aircraft BSC";
  public PAGE_CHARACTERS_STR_2_MODEL2 = "Flying altitude 7500m";
  public PAGE_CHARACTERS_STR_3_MODEL2 = "Speed: 650-800km/h";
  public PAGE_CHARACTERS_STR_4_MODEL2 = "Crew: 3";
  public PAGE_CHARACTERS_STR_5_MODEL2 = "Passengers: 32";
  public PAGE_CHARACTERS_STR_6_MODEL2 = "Flying range: 2500km";
  public PAGE_CHARACTERS_STR_7_MODEL2 = "Takeoff weight: 7500kg";
  public PAGE_CHARACTERS_STR_8_MODEL2 = "Load capacity: 3T";
  public PAGE_CHARACTERS_STR_9_MODEL2 = "Fully vertical takeoff and landing. High speed and range.";
  public PAGE_CHARACTERS_STR_9_1_MODEL2 = "Ability to move on the ground at a speed of 60 km/h.";
  public PAGE_CHARACTERS_STR_9_2_MODEL2 = "Can be used as a cargo, passenger or business class liner.";
  public PAGE_CHARACTERS_STR_9_3_MODEL2 = "The loading hatch is raised, giving direct access to the interior.";
  public PAGE_CHARACTERS_STR_10_MODEL2 = "Production cost";


  public PAGE_CHARACTERS_STR_1_MODEL3 = "Universal transport platform UTP-500";
  public PAGE_CHARACTERS_STR_2_MODEL3 = "Flying altitude: up to 7.500m";
  public PAGE_CHARACTERS_STR_3_MODEL3 = "Speed: up to 500km/h";
  public PAGE_CHARACTERS_STR_4_MODEL3 = "Crew: 16";
  public PAGE_CHARACTERS_STR_5_MODEL3 = "Passengers: 3350";
  public PAGE_CHARACTERS_STR_6_MODEL3 = "Flying range: up to 15000km";
  public PAGE_CHARACTERS_STR_7_MODEL3 = "Takeoff weight: 1100T";
  public PAGE_CHARACTERS_STR_8_MODEL3 = "Load capacity: 500T";
  public PAGE_CHARACTERS_STR_9_MODEL3 =
    "The versatile transport platform allows intercontinental flights and delivery of cargo and people to any place on the planet.";
  public PAGE_CHARACTERS_STR_9_1_MODEL3 =
    "The unique design allows for horizontal takeoff when fully loaded from solid and water surfaces.";
  public PAGE_CHARACTERS_STR_9_2_MODEL3 =
    "The aircraft assumes loading and unloading on its own or at any available cargo terminal.";
  public PAGE_CHARACTERS_STR_10_MODEL3 = "Production cost";

  public PAGE_CHARACTERS_STR_1_MODEL4 = "LASAR Aerostatic Unloading Aircraft";
  public PAGE_CHARACTERS_STR_2_MODEL4 = "Flying altitude: 30000m";
  public PAGE_CHARACTERS_STR_3_MODEL4 = "Speed: 700km/h";
  public PAGE_CHARACTERS_STR_4_MODEL4 = "Crew: 2000";
  public PAGE_CHARACTERS_STR_5_MODEL4 = "Passengers: 340.000";
  public PAGE_CHARACTERS_STR_6_MODEL4 = "Flying range: 42.000km";
  public PAGE_CHARACTERS_STR_7_MODEL4 = "Takeoff weight: 0T";
  public PAGE_CHARACTERS_STR_8_MODEL4 = "Load capacity up to 100.000T";
  public PAGE_CHARACTERS_STR_9_MODEL4 =
    "The world's first flying cruise liner with entertainment center infrastructure on board.";
  public PAGE_CHARACTERS_STR_9_1_MODEL4 =
    "The ultra-reliable design guarantees accident-free safety and passenger safety.";
  public PAGE_CHARACTERS_STR_9_2_MODEL4 = "Tourist attraction of the liner will ensure 100% occupancy.";
  public PAGE_CHARACTERS_STR_9_3_MODEL4 =
    "Capable of flying around the globe without landing, with the ability to hover in the most interesting places on the planet.";
  public PAGE_CHARACTERS_STR_10_MODEL4 = "Production cost";
  public PAGE_CHARACTERS_STR_10_MODEL4_1 = "Under contract";

  public NEXT_BUTTON = "Next model";
  public BACK_BUTTON = "Previous";
  public ALL_RIGHTS_RESERVED = "All rights reserved";
  public NAME_1 = "Tokhir Tukhtarov";
  public NAME_2 = "Evgeny Kuznetsov";
  public LINK_VIDEO = "https://www.youtube.com/embed/5E6PA-WwGsE";
}

export type LangProps = keyof LanguageKeys;
export default LanguageKeys;
/* eslint-enable */
