import Model1PNG from "shared/static/images/model1.png";
import Model2PNG from "shared/static/images/model2.png";
import Model3PNG from "shared/static/images/model3.png";
import Model4PNG from "shared/static/images/model4.png";
import { useT } from "../../i18n";

export const useText = () => {
  const t = useT();
  const models = {
    1: {
      image: Model1PNG,
      title: t("PAGE_CHARACTERS_STR_1_MODEL1"),
      heightOfFlightInM: 4000,
      sliderPercent: 25,
      heightOfFlight: t("PAGE_CHARACTERS_STR_2_MODEL1"),
      speed: t("PAGE_CHARACTERS_STR_3_MODEL1"),
      crew: t("PAGE_CHARACTERS_STR_4_MODEL1"),
      passengers: t("PAGE_CHARACTERS_STR_5_MODEL1"),
      distanceOfFlight: t("PAGE_CHARACTERS_STR_6_MODEL1"),
      takeoffWeight: t("PAGE_CHARACTERS_STR_7_MODEL1"),
      loadCapacity: t("PAGE_CHARACTERS_STR_8_MODEL1"),
      descriptions: [t("PAGE_CHARACTERS_STR_9_MODEL1"), t("PAGE_CHARACTERS_STR_9_1_MODEL1")],
      priceName: t("PAGE_CHARACTERS_STR_10_MODEL1"),
      price: t("PAGE_CHARACTERS_STR_11_MODEL1"),
    },
    2: {
      image: Model2PNG,
      title: t("PAGE_CHARACTERS_STR_1_MODEL2"),
      heightOfFlightInM: 7500,
      sliderPercent: 45,
      heightOfFlight: t("PAGE_CHARACTERS_STR_2_MODEL2"),
      speed: t("PAGE_CHARACTERS_STR_3_MODEL2"),
      crew: t("PAGE_CHARACTERS_STR_4_MODEL2"),
      passengers: t("PAGE_CHARACTERS_STR_5_MODEL2"),
      distanceOfFlight: t("PAGE_CHARACTERS_STR_6_MODEL2"),
      takeoffWeight: t("PAGE_CHARACTERS_STR_7_MODEL2"),
      loadCapacity: t("PAGE_CHARACTERS_STR_8_MODEL2"),
      descriptions: [
        t("PAGE_CHARACTERS_STR_9_MODEL2"),
        t("PAGE_CHARACTERS_STR_9_1_MODEL2"),
        t("PAGE_CHARACTERS_STR_9_2_MODEL2"),
        t("PAGE_CHARACTERS_STR_9_3_MODEL2"),
      ],
      priceName: t("PAGE_CHARACTERS_STR_10_MODEL2"),
      price: "4,500,000 $",
    },
    3: {
      image: Model3PNG,
      title: t("PAGE_CHARACTERS_STR_1_MODEL3"),
      heightOfFlightInM: 4000,
      sliderPercent: 45,
      heightOfFlight: t("PAGE_CHARACTERS_STR_2_MODEL3"),
      speed: t("PAGE_CHARACTERS_STR_3_MODEL3"),
      crew: t("PAGE_CHARACTERS_STR_4_MODEL3"),
      passengers: t("PAGE_CHARACTERS_STR_5_MODEL3"),
      distanceOfFlight: t("PAGE_CHARACTERS_STR_6_MODEL3"),
      takeoffWeight: t("PAGE_CHARACTERS_STR_7_MODEL3"),
      loadCapacity: t("PAGE_CHARACTERS_STR_8_MODEL3"),
      descriptions: [
        t("PAGE_CHARACTERS_STR_9_MODEL3"),
        t("PAGE_CHARACTERS_STR_9_1_MODEL3"),
        t("PAGE_CHARACTERS_STR_9_2_MODEL3"),
      ],
      priceName: t("PAGE_CHARACTERS_STR_10_MODEL3"),
      price: "11,000,000 $",
    },
    4: {
      image: Model4PNG,
      title: t("PAGE_CHARACTERS_STR_1_MODEL4"),
      heightOfFlightInM: 30000,
      sliderPercent: 100,
      heightOfFlight: t("PAGE_CHARACTERS_STR_2_MODEL4"),
      speed: t("PAGE_CHARACTERS_STR_3_MODEL4"),
      crew: t("PAGE_CHARACTERS_STR_4_MODEL4"),
      passengers: t("PAGE_CHARACTERS_STR_5_MODEL4"),
      distanceOfFlight: t("PAGE_CHARACTERS_STR_6_MODEL4"),
      takeoffWeight: t("PAGE_CHARACTERS_STR_7_MODEL4"),
      loadCapacity: t("PAGE_CHARACTERS_STR_8_MODEL4"),
      descriptions: [
        t("PAGE_CHARACTERS_STR_9_MODEL4"),
        t("PAGE_CHARACTERS_STR_9_1_MODEL4"),
        t("PAGE_CHARACTERS_STR_9_2_MODEL4"),
        t("PAGE_CHARACTERS_STR_9_3_MODEL4"),
      ],
      priceName: t("PAGE_CHARACTERS_STR_10_MODEL4"),
      price: t("PAGE_CHARACTERS_STR_10_MODEL4_1"),
    },
  };

  return { models };
};
