import { memo } from "react";
import { Carousel } from "@mantine/carousel";
import { createStyles, Paper } from "@mantine/core";
import sl1 from "shared/static/images/sl1.jpg";
import sl2 from "shared/static/images/sl2.jpg";
import sl3 from "shared/static/images/sl3.jpg";
import sl4 from "shared/static/images/sl4.jpg";
import sl5 from "shared/static/images/sl5.jpg";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";

const useStyles = createStyles((theme) => ({
  card: {
    height: 400,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    overflow: "hidden",
    borderRadius: 30,
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      borderRadius: 0,
    },
  },

  cardBackground: {
    background: "rgba(0,0,0,.1)",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxSizing: "border-box",
    padding: "12px 24px 24px",
  },
}));

interface CardProps {
  image: string;
}

function Card({ image }: CardProps) {
  const { classes } = useStyles();

  return (
    <Paper shadow="md" p="xl" radius="md" sx={{ backgroundImage: `url(${image})` }} className={classes.card}>
      <div className={classes.cardBackground} />
    </Paper>
  );
}

const data = [{ image: sl3 }, { image: sl4 }, { image: sl5 }];

export const CarouselProjects = memo(() => {
  const { isMobile } = useAdaptive();

  const slides = data.map((item, k: number) => (
    <Carousel.Slide key={`sl${k}`}>
      <Card {...item} />
    </Carousel.Slide>
  ));

  return (
    <Carousel
      slideSize={isMobile ? "100%" : "33.333%"}
      breakpoints={[{ maxWidth: "sm", slideSize: "100%", slideGap: 16 }]}
      slideGap="sm"
      align="start"
      slidesToScroll={isMobile ? 1 : 3}>
      {slides}
    </Carousel>
  );
});
