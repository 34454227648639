import React, { useState } from "react";
import { createStyles, Container, Text, Group, Badge, ThemeIcon, Drawer, NavLink } from "@mantine/core";
import { RunTicker } from "components/RunTicker";
import { useTrail, a } from "@react-spring/web";
import classNames from "classnames";
import { Menu2 } from "tabler-icons-react";
import Main1Image from "shared/static/images/main1.png";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";
import { useDisclosure, useViewportSize, useWindowScroll } from "@mantine/hooks";
import { IconChevronRight, IconLanguage } from "@tabler/icons";
import { ReactComponent as Logo } from "../../shared/static/icons/logo2.svg";
// @ts-ignore
import video from "../../video.mp4";
import { Screen2 } from "../Screen2";
import { Screen3 } from "../Screen3";
import { Screen4 } from "../Screen4";
import { Screen5 } from "../Screen5";
import { Screen6 } from "../Screen6";
import { Screen7 } from "../Screen7";
import { Screen8} from "../Screen8";
import { Footer } from "../../components/common/Footer";
import { T, useT, useSetLang, useLang } from "../../i18n";
import { ScreenVideo } from "../ScreenVideo";

const BreakPointMaximalMobile = `@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`;
const BreakPointMinimalWeb = "@media (max-width: 1100px)";

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    position: "relative",
    minHeight: "100vh",
    boxSizing: "border-box",
    backgroundColor: "rgba(0,0,0,0.3)",
    backgroundSize: "cover",
    display: "flex",
    overflow: "hidden",
  },

  inner: {
    position: "relative",
    width: "calc(100% - 160px)",
    margin: "auto 80px",

    [BreakPointMaximalMobile]: {
      paddingBottom: 80,
      paddingTop: 80,
      width: "100%",
      margin: "auto 20px",
    },
  },

  title: {
    "fontFamily": `${theme.fontFamily}`,
    "fontSize": 45,
    "fontWeight": 900,
    "lineHeight": 1.1,
    "margin": "0 0 0 -5px",
    "padding": 0,
    "color": theme.white,

    " span": {
      textShadow: "0 0 40px rgba(0,0,0,.5)",
    },

    [BreakPointMaximalMobile]: {
      fontSize: 20,
      lineHeight: 1.2,
    },
  },

  badge: {
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },

  description: {
    fontSize: 40,
    maxWidth: 600,
    fontWeight: 500,
    lineHeight: "50px",
    textShadow: "0 0 40px black",

    [BreakPointMaximalMobile]: {
      fontSize: 22,
    },
  },

  rightMain: {
    "position": "absolute",
    "right": 0,
    "top": 0,
    "height": "100vh",
    "display": "flex",

    " img": {
      margin: "8vh 0 8vh auto",
      height: "84vh",
    },

    [BreakPointMinimalWeb]: {
      display: "none",
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 1.3,

    [BreakPointMaximalMobile]: {
      marginTop: theme.spacing.xl,
    },
  },

  controlsIcon: {
    marginRight: "10px",
  },

  control: {
    height: 54,
    paddingLeft: 20,
    paddingRight: 23,

    [BreakPointMaximalMobile]: {
      height: 54,
      paddingLeft: 18,
      paddingRight: 18,
      flex: 1,
    },
  },

  logo: {
    height: "35px",
    color: "white",
    margin: "auto 0",
    boxSizing: "content-box",
    backgroundColor: "rgba(0,0,0,0.7)",
    padding: "12.5px",
    borderRadius: "0 0 8px 8px",
    marginLeft: "8px",
  },

  menu: {
    height: "35px",
    backgroundColor: "rgba(0,0,0,0.7)",
    padding: "12.5px",
    boxSizing: "content-box",
    display: "flex",
    cursor: "pointer",
    borderRadius: "0 0 8px 8px",
  },

  trailsText: {
    "position": "relative",
    "width": "100%",
    "height": "70px",
    "lineHeight": "60px",
    "color": "white",
    "fontSize": "60px",
    "willChange": "transform, opacity",
    "overflow": "hidden",
    [BreakPointMaximalMobile]: {
      fontSize: "40px",
      height: "50px",
    },
    "& > div": {
      overflow: "hidden",
    },
  },

  video: {
    position: "absolute",
    right: 0,
    bottom: 0,
    minWidth: "100vw",
    minHeight: "100vh",
    zIndex: -1,
    [BreakPointMaximalMobile]: {
      transform: "rotate(180deg)",
      width: 800,
    },
  },

  header: {
    width: "100%",
    height: "60px",
    display: "flex",
    position: "absolute",
    left: 0,
    right: 0,
    top: "0px",
    zIndex: 2,
    padding: "0 96px",
    transition: "all 300ms ease",
    [BreakPointMaximalMobile]: {
      padding: 0,
    },
  },
  headerScrolled: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.6)",
    backdropFilter: "blur(10px)",
  },
}));

const Header = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [scroll] = useWindowScroll();
  const { height } = useViewportSize();
  const { classes } = useStyles();
  const t = useT();
  const setLang = useSetLang();
  const lang = useLang();

  return (
    <div
      className={classNames(classes.header, {
        [classes.headerScrolled]: scroll.y > height,
      })}
      style={scroll.y > height ? { background: "transparent", padding: "0 10px" } : {}}>
      <div className={classes.menu} style={scroll.y > height ? { background: "transparent" } : {}}>
        <ThemeIcon variant="light" radius="md" size="lg" color="dark" onClick={open}>
          <Menu2 />
        </ThemeIcon>
        <Drawer opened={opened} shadow="md" onClose={close}>
          <NavLink
            label="Русский"
            color="blue"
            icon={<IconLanguage size="1rem" stroke={1.5} />}
            rightSection={<IconChevronRight size="0.8rem" stroke={1.5} />}
            active={lang === "ru"}
            onClick={() => {
              setLang("ru");
              localStorage.setItem("lang", "ru");
            }}
          />
          <NavLink
            label="English"
            color="blue"
            icon={<IconLanguage size="1rem" stroke={1.5} />}
            rightSection={<IconChevronRight size="0.8rem" stroke={1.5} />}
            active={lang === "en"}
            onClick={() => {
              setLang("en");
              localStorage.setItem("lang", "en");
            }}
          />
        </Drawer>
      </div>
      <div className={classes.logo}>
        <Logo height="35px" />
      </div>
    </div>
  );
};

const Trail = ({ open, children }: any) => {
  const { isMobile } = useAdaptive();
  const items = React.Children.toArray(children);
  const { classes } = useStyles();
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 4000, friction: 700 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? (isMobile ? 50 : 110) : 0,
    from: { opacity: 0, x: 220, height: 0 },
  });
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div key={index} className={classes.trailsText} style={style}>
          {items[index]}
        </a.div>
      ))}
    </div>
  );
};

export const WelcomeSection = () => {
  const { classes } = useStyles();
  const { isMobile } = useAdaptive();
  const [isOpenTrail] = useState(true);
  const t = useT();

  return (
    <div className={classes.wrapper}>
      <video className={classes.video} autoPlay muted loop>
        <source src={video} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <Header />
      {!isMobile && (
        <div className={classes.rightMain}>
          <img src={Main1Image} height="100vh" alt="" />
        </div>
      )}
      <Container size={960} className={classes.inner}>
        <div className="w-100 display_flex">
          <div>
            <h1 className={classes.title}>
              <Trail open={isOpenTrail}>
                {t("MAIN_TITLE")
                  .split("/")
                  .map((word) => (
                    <span key={`word${word}`}>{word.trim()}</span>
                  ))}
              </Trail>
            </h1>
            <div className={classes.badge}>
              <Group>
                <Badge color="black" size="md" variant="light">
                  {t("MAIN_UNIQUE")}
                </Badge>
                <Badge color="pink" size="md" variant="light">
                  {t("MAIN_ALLOWED")}
                </Badge>
                <Badge color="blue" size="md" variant="light">
                  {t("MAIN_SAFETY")}
                </Badge>
                <Badge color="yellow" size="md" variant="light">
                  {t("MAIN_FUTURE")}
                </Badge>
              </Group>
            </div>
            <Text className={classes.description} color="white">
              {t("MAIN_DESCRIPTION")}
            </Text>
          </div>
        </div>
      </Container>
      <RunTicker text={t("MAIN_TICKER")} />
    </div>
  );
};

export const Main = () => {
  return (
    <div>
      <WelcomeSection />
      <Screen2 />
      <Screen3 />
      <ScreenVideo />
      <Screen4 />
      <Screen5 />
      <Screen6 />
      <Screen7 />
      <Screen8 />
      <Footer />
    </div>
  );
};
