import { Button, Chip, createStyles, Divider, Group, Slider, Space, Text } from "@mantine/core";
import React, { useState } from "react";
import Model1PNG from "shared/static/images/model1.png";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";
import { switchMatch } from "shared/utils";
// @ts-ignore
import video from "../../video5.mp4";
import { Padding } from "../../components/ui/universal/PaddingWrapper";
import { useText } from "./useText";
import { useT } from "../../i18n";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    position: "relative",
    minHeight: "100vh",
    boxSizing: "border-box",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    userSelect: "none",
    backgroundColor: "#060916cf",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      flexDirection: "column-reverse",
    },
  },

  video: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    top: 0,
    minWidth: "100vw",
    minHeight: "100vh",
    zIndex: -1,
  },

  informationBlock: {
    width: "40%",
    backgroundColor: "rgba(0,0,0,0.8)",
    backdropFilter: "blur(12px)",
    borderRadius: "0 16px 16px 0",
    paddingLeft: "10px",
    paddingRight: "10px",
    margin: "16px 24px 16px 0",
    color: "white",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      width: "100%",
      margin: 0,
      borderRadius: 0,
      backgroundColor: "black",
    },
  },
  visualBlock: {
    flex: 1,
    padding: "25px 0 25px 30px",
    boxSizing: "border-box",
    display: "flex",
    img: {
      margin: "auto 0 auto auto",
    },
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      padding: 0,
    },
  },
  visualBlockButtons: {
    position: "absolute",
    top: 100,
    right: 20,
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      position: "relative",
      right: "auto",
    },
  },
}));

export const Screen5 = () => {
  const { isMobile } = useAdaptive();
  const { models } = useText();
  const [currentModel, setCurrentModel] = useState(1);
  const { classes } = useStyles();
  const t = useT();
  // @ts-ignore
  const currentTexts = models[currentModel];

  const back = () => {
    setCurrentModel(
      switchMatch(currentModel.toString(), {
        "1": () => {},
        "2": 1,
        "3": 2,
        "4": 3,
      }),
    );
  };

  const next = () => {
    setCurrentModel(
      switchMatch(currentModel.toString(), {
        "1": 2,
        "2": 3,
        "3": 4,
        "4": () => {},
      }),
    );
  };

  const controlButtons = (
    <Group>
      <Button
        fullWidth={isMobile}
        disabled={currentModel === 1}
        size="xl"
        variant="filled"
        color="violet"
        onClick={back}>
        {t("BACK_BUTTON")}
      </Button>
      <Button
        fullWidth={isMobile}
        disabled={currentModel === 4}
        size="xl"
        variant="filled"
        color="violet"
        onClick={next}>
        {t("NEXT_BUTTON")}
      </Button>
    </Group>
  );

  const titleBlock = (
    <Text size={24} weight={800}>
      {currentTexts.title}
    </Text>
  );

  const informationText1 = (
    <div className={classes.informationBlock}>
      <Padding horizontal={3} vertical={2}>
        {!isMobile && titleBlock}
        {isMobile && controlButtons}
        <Space mt={16} />
        <Divider />
        <Space mt={16} />
        <div>
          <Text size={16} weight={400}>
            {currentTexts.heightOfFlight}
          </Text>
        </div>
        <Space mt={16} />
        <div>
          <Slider
            color="blue"
            size="md"
            value={currentTexts.sliderPercent}
            radius={0}
            showLabelOnHover={false}
            label={currentTexts.heightOfFlightInM}
            marks={[
              { value: 5, label: "2000м" },
              { value: 25, label: "4000м" },
              { value: 80, label: "13000м" },
              { value: 100, label: "> 13km" },
            ]}
          />
        </div>
        <Space mt={30} />
        <div>
          <Group mb={16}>
            <Chip checked color="green" variant="filled" size="xs" radius="md">
              {currentTexts.speed}
            </Chip>
            <Chip checked={false} color="white" variant="filled" size="xs" radius="md">
              {currentTexts.crew}
            </Chip>
            <Chip checked color="yellow" variant="filled" size="xs" radius="md">
              {currentTexts.passengers}
            </Chip>
          </Group>
          <Text size={16} weight={400}>
            {currentTexts.distanceOfFlight}
            <br />
            {currentTexts.takeoffWeight}
          </Text>
          {currentTexts.descriptions.map((text: string, i: number) => (
            <div key={`tdescr${i}`}>
              <Divider mt={16} mb={16} />
              <Text>{text}</Text>
            </div>
          ))}
        </div>
        <Divider mt={16} mb={16} />
        {currentTexts.price !== null && (
          <div>
            <div>{currentTexts.priceName}</div>
            <div>
              <Text
                variant="gradient"
                sx={{ letterSpacing: "1px" }}
                gradient={{ from: "white", to: "violet", deg: 45 }}
                ta="left"
                fz={50}
                fw={700}>
                {currentTexts.price}
              </Text>
            </div>
          </div>
        )}
      </Padding>
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {!isMobile && (
        <video className={classes.video} autoPlay muted loop>
          <source src={video} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      )}
      {informationText1}
      <div className={classes.visualBlock} style={isMobile ? { flexDirection: "column" } : {}}>
        {isMobile && (
          <Padding horizontal={3} vertical={2}>
            {titleBlock}
          </Padding>
        )}
        <img src={currentTexts.image} alt="model 1" />
        <div className={classes.visualBlockButtons}>{!isMobile && controlButtons}</div>
      </div>
    </div>
  );
};
