import "./shared/static/styles/common.scss";
import { useEffect } from "react";
import { useRoute } from "react-router5";
import { MantineProvider } from "@mantine/core";
import { theme } from "shared/utils/mantine/theme";
import { store } from "store/store";
import { ParallaxProvider } from "react-scroll-parallax";
import { Provider } from "react-redux";
import Routes from "./routes";
import { LangProvider } from "./i18n";

export default function App(props: any) {
  const { route } = useRoute();

  useEffect(() => {
    setTimeout(() => window?.scrollTo(0, 0), 100);
  }, []);

  return (
    <MantineProvider theme={theme} withCSSVariables withGlobalStyles withNormalizeCSS>
      <Provider store={store}>
        <ParallaxProvider>
          <LangProvider>
            <Routes router={route !== undefined ? route : {}} {...props} />
          </LangProvider>
        </ParallaxProvider>
      </Provider>
    </MantineProvider>
  );
}
