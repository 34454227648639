import {createStyles, Title, Space, Grid, Divider} from "@mantine/core";
import React from "react";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";
import BG2 from "shared/static/images/bg7.jpg";
import S6JPG from "shared/static/images/s6.jpg";
import { Padding } from "../../components/ui/universal/PaddingWrapper";
import { useT } from "../../i18n";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    position: "relative",
    boxSizing: "border-box",
    minHeight: "100vh",
    backgroundSize: "1920px",
    backgroundRepeat: "repeat",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    paddingTop: "50px",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      paddingTop: 0,
    },
  },
}));

export const Screen8 = () => {
  const { classes } = useStyles();
  const { isMobile } = useAdaptive();
  const t = useT();

  return (
    <div className={classes.wrapper} style={isMobile ? {} : { backgroundImage: `url(${BG2})` }}>
      <div>
        <Padding horizontal={4} vertical={2}>
          <Title order={isMobile ? 5 : 3} weight={200} color="white" ta="left" mb={20}>
            <div dangerouslySetInnerHTML={{__html: t("PAGE8_STR_1")}}/>
          </Title>
          <Title order={isMobile ? 3 : 1} color="white" ta="left">
            {t("PAGE8_STR_2")}
          </Title>
          <Space mt={30} />
          <Padding vertical={2}>
            <Grid columns={isMobile ? 1 : 2} gutter={50}>
              <Grid.Col span={1}>
                <div dangerouslySetInnerHTML={{ __html: t("PAGE8_STR_3") }}/>
              </Grid.Col>
              <Grid.Col span={1}>
                <div dangerouslySetInnerHTML={{ __html: t("PAGE8_STR_4") }}/>
                <Space mt={10}/>
                <Title order={isMobile ? 6 : 4} weight={200}>
                  {t("PAGE8_STR_6")}
                </Title>
                <Space mt={10}/>
                <Title order={isMobile ? 3 : 2} weight={600} color="lightgreen">
                  {t("PAGE8_STR_7")}
                </Title>
                <Space mt={10}/>
                <Title order={isMobile ? 6 : 4} weight={200}>
                  {t("PAGE8_STR_8")}
                </Title>
                <Space mt={10}/>
                <Title order={isMobile ? 3 : 2} weight={600} color="lightgreen">
                  {t("PAGE8_STR_9")}
                </Title>
              </Grid.Col>
            </Grid>
            <div>
              <div dangerouslySetInnerHTML={{ __html: t("PAGE8_STR_10") }}/>
            </div>
            <div>
              <Title order={isMobile ? 3 : 1} color="white" ta="left">
                {t("PAGE8_STR_11")}
              </Title>
            </div>
            <Space mt={16}/>
            <div>
              <div dangerouslySetInnerHTML={{ __html: t("PAGE8_STR_12") }}/>
            </div>
          </Padding>
        </Padding>
      </div>
      <Space mt={50} />
    </div>
  );
};
