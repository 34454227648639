import { createStyles, Text, Container, ActionIcon, Group, Space } from "@mantine/core";
import { IconBrandWhatsapp, IconBrandTelegram, IconBrandMailgun } from "@tabler/icons-react";
import { ReactComponent as Logo } from "shared/static/icons/logo2.svg";
import { ADAPTIVE_BREAKPOINT } from "shared/hooks";
import { useT } from "../../../i18n";

const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: "36px",
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]}`,
  },

  logo: {
    maxWidth: "200px",

    [theme.fn.smallerThan("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },

  description: {
    marginTop: "5px",

    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
      textAlign: "center",
    },
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  groups: {
    display: "flex",
    flexWrap: "wrap",

    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      margin: "auto",
      textAlign: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },

  wrapper: {
    paddingLeft: "24px",
    justifyContent: "flex-end",
    textAlign: "right",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      margin: "auto",
      justifyContent: "center",
      textAlign: "center",
      paddingLeft: 0,
    },
  },

  link: {
    "cursor": "pointer",
    "display": "flex",
    "color": theme.colorScheme === "dark" ? theme.colors.dark[1] : theme.colors.gray[6],
    "fontSize": theme.fontSizes.sm,
    "alignItems": "center",
    "justifyContent": "end",
    "&:hover": {
      color: "white",
    },
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      margin: "auto",
      textAlign: "center",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      marginTop: "10px",
    },
  },

  afterFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  social: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

export function Footer() {
  const { classes } = useStyles();
  const t = useT();

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Logo />
          <Text size="xs" color="dimmed" className={classes.description}>
            {t("MAIN_TITLE").split("/").join(" ")}
          </Text>
        </div>
        <div className={classes.groups}>
          <div className={classes.link}>
            <div className={classes.wrapper}>
              <Text className={classes.title}>{t("NAME_2")}</Text>
              <Space mt={12} />
              <div
                className={classes.link}
                onClick={() => {
                  window.open("https://wa.me/79649108108", "_blank");
                }}>
                <IconBrandWhatsapp size="1.2rem" stroke={1.5} />
                <Space mr={8} />
                <div>+7-964-910-81-08</div>
              </div>
              <Space mt={12} />
              <div
                className={classes.link}
                onClick={() => {
                  window.open("https://t.me/+79649108108", "_blank");
                }}>
                <IconBrandTelegram size="1.2rem" stroke={1.5} />
                <Space mr={8} />
                <div>+7-964-910-81-08</div>
              </div>
              <Space mt={12} />
              <div
                className={classes.link}
                onClick={() => {
                  window.open("mailto:aero@xwu.plus", "_blank");
                }}>
                <IconBrandMailgun size="1.2rem" stroke={1.5} />
                <Space mr={8} />
                <div>aero@xwu.plus</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text color="dimmed" size="sm">
          © 2023 X-WORLD. {t("ALL_RIGHTS_RESERVED")}
        </Text>
      </Container>
    </footer>
  );
}
