import { useMediaQuery } from "@mantine/hooks";

export const ADAPTIVE_BREAKPOINT = 1000;

export const useAdaptive = () => {
  const isMobile = useMediaQuery(`(max-width: ${ADAPTIVE_BREAKPOINT}px)`);
  const isDesktop = useMediaQuery(`(min-width: ${ADAPTIVE_BREAKPOINT + 1}px)`);

  return {
    isMobile,
    isDesktop,
  };
};
