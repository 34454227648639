/* eslint-disable */
import LanguageKeys from "./en";

export class LanguageKeysRu extends LanguageKeys {
  public WELCOME_HEADLINE = "привет";

  public MESSAGES = ({ count }: { count: number }) => {
    return count === 1 ? `${count} человек` : `${count} что-то там`;
  };

  public MAIN_TITLE = "Летательные/аппараты/вертикального/взлета";
  public MAIN_DESCRIPTION = "Революция в сфере логистических цепочек";
  public MAIN_UNIQUE = "Уникальное";
  public MAIN_SAFETY = "Безопасное";
  public MAIN_ALLOWED = "Доступное";
  public MAIN_FUTURE = "Будущее";
  public MAIN_TICKER =
    "Способен взлетать с любого грунта, при этом достаточно 25 метров взлетной полосы. Использует автомобильный бензин и электромотор от солнечных батарей. Легок в управлении, взлете и посадке. Стабилен даже при ветре в 15 м/с (обычный самолет выдерживает 7 м/с). Полностью вертикальный взлет и посадка. Высокая скорость и дальность полета. Возможность передвижения по земле со скоростью 60 км/ч. Может использоваться как грузовой, пассажирский или лайнер бизнес-класса. Погрузочный люк поднимается, открывая прямой доступ к салону.";
  public PAGE2_TYPES = "4 типа летательных аппаратов нового поколения";
  public PAGE2_TYPES_MOBILE = "4 типа летательных аппаратов";
  public PAGE2_TYPE_1 = "Легкая авиация: малогабаритный пятиместный самолет";
  public PAGE2_TYPE_2 =
    "Пассажирские и грузовые перевозки: 30-местный самолет на замену вертолетам и частично суперджетам";
  public PAGE2_TYPE_3 = "Грузовые перевозки на большие расстояния: инновация в сфере грузоперевозок";
  public PAGE2_TYPE_4 = "Лазарь - первый в мире воздушный круизный лайнер";

  public PAGE3_TITLE = "Для строительства необходимо";

  public PAGE3_STR_1 = "Создание завода по производству разработанного нами материала корпуса";
  public PAGE3_STR_2 = "Запуск линии производства запчастей и комплектующих материалов";
  public PAGE3_STR_3 =
    "Строительство сборочных цехов для трех моделей и оборудование специальной зоны для строительства четвертой модели";
  public PAGE3_STR_4 = "500.000.000 $";
  public PAGE3_STR_5 = "Бюджет на полную";
  public PAGE3_STR_6 = "реализацию проекта";
  public PAGE3_STR_7 = "10.000.000 $";
  public PAGE3_STR_8 = "Бюджет на";
  public PAGE3_STR_9 = "строительство завода комплектующих и";
  public PAGE3_STR_10 = "запуск";
  public PAGE3_STR_11 = "первой линии";

  public PAGE4_STR_1 = "Сроки реализации проекта";
  public PAGE4_STR_2 = "6 мес";
  public PAGE4_STR_3 = "Строительство завода";
  public PAGE4_STR_4 = "1 — 1,5 меc";
  public PAGE4_STR_5 = "Сборка модели";
  public PAGE4_STR_6 = "2 — 4 мес";
  public PAGE4_STR_7 = "Сборка модели";
  public PAGE4_STR_8 = "3 — 6 мес";
  public PAGE4_STR_9 = "Сборка модели";

  public PAGE4_STR_10 = "Транш 1";
  public PAGE4_STR_11 = "10 млн $ до запуска производства, сразу после подписания контракта";
  public PAGE4_STR_12 = "Транш 2";
  public PAGE4_STR_13 =
    "50 млн $ после окончания строительства завода, производственной линии и сборки моделей номер 1 и 2";
  public PAGE4_STR_14 = "Транш 3";
  public PAGE4_STR_15 = "150 млн $ после сборки модели номер 3 ";
  public PAGE4_STR_16 = "Транш 4";
  public PAGE4_STR_17 = "400 млн $ перед началом строительства воздушного лайнера";
  public PAGE4_STR_18 = "Транш 5";
  public PAGE4_STR_19 =
    "390 миллионов после ввода в эксплуатацию 1,2 и 3 моделей для строительства (40) образца 1 модели (24) образца 2 модели (8) образцов 3 модели";

  public PAGE6_STR_1 = "Произведены 5 самолетов и летные испытания первой модели";
  public PAGE6_STR_2 = "Модели 2 и 3 созданы в макете и протестированы";
  public PAGE7_STR_1 =
    "Создание летательных аппаратов нового поколения полностью изменит мировые логистические цепочки";
  public PAGE7_STR_2 = "Революционные изменения затронут как грузовые, так и пассажирские перевозки";
  public PAGE7_STR_3 = "Владельцы производств получат возможность контролировать систему грузоперевозок";
  public PAGE7_STR_4 =
    "Компания будет получать прибыль от продажи модели 1 и 2, а также от грузоперевозок модели 3 и 4 (они не будут продаваться на свободном рынке).";

  public PAGE_CHARACTERS_STR_1_MODEL1 = "Самолет безаэродромного базирования МП-1";
  public PAGE_CHARACTERS_STR_2_MODEL1 = "Высота полёта 4000м";
  public PAGE_CHARACTERS_STR_3_MODEL1 = "Скорость: 300км/ч";
  public PAGE_CHARACTERS_STR_4_MODEL1 = "1 пилот";
  public PAGE_CHARACTERS_STR_5_MODEL1 = "4 пассажира";
  public PAGE_CHARACTERS_STR_6_MODEL1 = "Дальность полёта: 1500км";
  public PAGE_CHARACTERS_STR_7_MODEL1 = "Взлётная масса: 1400т";
  public PAGE_CHARACTERS_STR_8_MODEL1 = "Грузоподьемность: 450кг";
  public PAGE_CHARACTERS_STR_9_MODEL1 =
    "Способен взлетать с любого грунта, при этом достаточно 25 метров взлетной полосы. Использует автомобильный бензин и электромотор от солнечных батарей";
  public PAGE_CHARACTERS_STR_9_1_MODEL1 =
    "Легок в управлении, взлете и посадке. Стабилен даже при ветре в 15 м/с (обычный самолет выдерживает 7 м/с)";
  public PAGE_CHARACTERS_STR_10_MODEL1 = "Стоимость изготовления";
  public PAGE_CHARACTERS_STR_11_MODEL1 = "750.000 $";

  public PAGE_CHARACTERS_STR_1_MODEL2 = "Самолет безаэродромного базировани БСК";
  public PAGE_CHARACTERS_STR_2_MODEL2 = "Высота полёта до 7500м";
  public PAGE_CHARACTERS_STR_3_MODEL2 = "Скорость 650-800км/ч";
  public PAGE_CHARACTERS_STR_4_MODEL2 = "Экипаж 3 человека";
  public PAGE_CHARACTERS_STR_5_MODEL2 = "32 пассажира";
  public PAGE_CHARACTERS_STR_6_MODEL2 = "Дальность полёта: 2500км";
  public PAGE_CHARACTERS_STR_7_MODEL2 = "Взлетная масса: 7500кг";
  public PAGE_CHARACTERS_STR_8_MODEL2 = "Грузоподьемность: 3т";
  public PAGE_CHARACTERS_STR_9_MODEL2 = "Полностью вертикальный взлет и посадка. Высокая скорость и дальность полета.";
  public PAGE_CHARACTERS_STR_9_1_MODEL2 = "Возможность передвижения по земле со скоростью 60км/ч.";
  public PAGE_CHARACTERS_STR_9_2_MODEL2 = "Может использоваться как грузовой, пассажирский или лайнер бизнес-класса.";
  public PAGE_CHARACTERS_STR_9_3_MODEL2 = "Погрузочный люк поднимается, открывая прямой доступ к салону.";
  public PAGE_CHARACTERS_STR_10_MODEL2 = "Стоимость изготовления";

  public PAGE_CHARACTERS_STR_1_MODEL3 = "Универсальная транспортная платформа УТП-500";
  public PAGE_CHARACTERS_STR_2_MODEL3 = "Высота полёта до 7500м";
  public PAGE_CHARACTERS_STR_3_MODEL3 = "Скорость до 500км/ч";
  public PAGE_CHARACTERS_STR_4_MODEL3 = "Экипаж 16 человек";
  public PAGE_CHARACTERS_STR_5_MODEL3 = "3350 пассажиров";
  public PAGE_CHARACTERS_STR_6_MODEL3 = "Дальность полёта: до 15.000км";
  public PAGE_CHARACTERS_STR_7_MODEL3 = "Взлетная масса: 1100т";
  public PAGE_CHARACTERS_STR_8_MODEL3 = "Грузоподьемность: 500т";
  public PAGE_CHARACTERS_STR_9_MODEL3 =
    "Универсальная транспортная платформа позволяет совершать межконтинентальные перелеты и доставлять грузы и людей в любое место планеты.";
  public PAGE_CHARACTERS_STR_9_1_MODEL3 =
    "Уникальная конструкция позволяет совершать горизонтальный взлет при полной загрузке с твердой и водной поверхности.";
  public PAGE_CHARACTERS_STR_9_2_MODEL3 =
    "Воздушное судно предполагает погрузку и разгрузку самостоятельно или в любом имеющемся грузовом терминале.";
  public PAGE_CHARACTERS_STR_10_MODEL3 = "Стоимость изготовления";

  public PAGE8_STR_1 = "Мы привлекаем $500 млн на строительство производства<br/>" + "и выпуск 78 самолетов марки МП-1, МП-2, МП-3.";
  public PAGE8_STR_2 = "Сколько на этом можно заработать";
  public PAGE8_STR_3 = "<h3><strong>Себестоимость МП-1 $320.000</strong><h3/>" +
    "<h3><strong>Рыночная цена МП-1 $750.000</strong></h3>" +
    "<h3><strong>Рентабельность в год $20.640.000</strong></h3>" +
    "<h3><strong>с 4 производственных линий - 48 шт в год.</strong></h3></br>" +
    "" +
    "<h3><strong>Себестоимость МП-2 - $1.050.000</strong><h3/>" +
    "<h3><strong>Рыночная цена $4.500.000</strong></h3>" +
    "<h3><strong>Рентабельность в год $82.800.000</strong></h3>" +
    "<h3><strong>с 4 производственных линий - 24 шт в год</strong></h3></br></br>";

  public PAGE8_STR_4 = "<h3><strong>Себестоимость МП-3 - $11.000.000</strong><h3/>" +
    "<h3><strong>с 2 производственных линий - 6 шт в год</strong></h3>" +
    "</br></br>";


  public PAGE8_STR_6 = "Эксплуатационный доход с аренды 6 аппаратов в год:";
  public PAGE8_STR_7 = "$216.000.000";
  public PAGE8_STR_8 = "Общая рентабельность проекта в год:";
  public PAGE8_STR_9 = "$319.000.000";

  public PAGE8_STR_10 = "В связи с арендной эксплуатацией МП-3 рентабельность увеличивается ежегодно<br/>" +
    "на $216.000.000 и составит на второй год $535 млн.<br/>" +
    "<br/>" +
    "Важно понимать, что на создание производственных линий и получение лицензий<br/>" +
    "может уйти порядка двух лет, поэтому полноценный доход от проекта стоит рассчитывать на третий год.<br/>" +
    "<br/>" +
    "МП-1 уже прошел летные испытания. Лицензия на него будет получена в течение 6-12 месяцев.<br/><br/>";
  public PAGE8_STR_11 = "Дорожная карта";
  public PAGE8_STR_12 = "<strong>Через 6 месяцев</strong><br/>" +
    "Создание завода производства сырья для изготовления корпусов <br/>" +
    "и элементов летательных аппаратов<br/>" +
    "<br/>" +
    "<strong>Через 12 месяцев</strong><br/>" +
    "Создание 5 производственных линий МП-1, МП-2 и МП-3<br/>" +
    "<br/>" +
    "<strong>Через 1,5 года</strong><br/>" +
    "Создание первых 12 моделей МП-1 6 моделей МП - 2 и 2 моделей МП - 3<br/>" +
    "<br/>" +
    "<strong>Через 2 года</strong><br/>" +
    "Создание еще 5 производственных линий МП-1, МП-2 и МП-3<br/>" +
    "Выход на уровень создания 24 моделей МП-1, 12 моделей МП-2 и 6 моделей МП-3<br/>" +
    "<br/>" +
    "<strong>В пределах 2-х лет</strong><br/>" +
    "Получение лицензий и проведение летных испытаний<br/>";

  public PAGE_CHARACTERS_STR_1_MODEL4 = "Летательный аппарат с аэростатической разгрузкой LASAR / ЛАЗАРЬ";
  public PAGE_CHARACTERS_STR_2_MODEL4 = "Высота полёта 30000м";
  public PAGE_CHARACTERS_STR_3_MODEL4 = "Скорость: 700км/ч";
  public PAGE_CHARACTERS_STR_4_MODEL4 = "Экипаж: 2000 человек";
  public PAGE_CHARACTERS_STR_5_MODEL4 = "340.000 человек";
  public PAGE_CHARACTERS_STR_6_MODEL4 = "Дальность полёта: 42.000км";
  public PAGE_CHARACTERS_STR_7_MODEL4 = "Взлётная масса: 0т";
  public PAGE_CHARACTERS_STR_8_MODEL4 = "Грузоподъемность до 100.000т";
  public PAGE_CHARACTERS_STR_9_MODEL4 =
    "Первый в мире летающий круизный лайнер с инфраструктурой развлекательного центра на борту.";
  public PAGE_CHARACTERS_STR_9_1_MODEL4 =
    "Сверхнадежная конструкция гарантирует безаварийный полет и безопасность пассажиров.";
  public PAGE_CHARACTERS_STR_9_2_MODEL4 =
    "Туристическая привлекательность лайнера позволит обеспечить 100% заполняемость.";
  public PAGE_CHARACTERS_STR_9_3_MODEL4 =
    "Способен облететь вокруг земного шара без посадок, с возможностью зависать в самых интересных местах планеты.";
  public PAGE_CHARACTERS_STR_10_MODEL4 = "Стоимость изготовления";
  public PAGE_CHARACTERS_STR_10_MODEL4_1 = "Договорная";

  public NEXT_BUTTON = "Следующая модель";
  public BACK_BUTTON = "Назад";
  public ALL_RIGHTS_RESERVED = "Все права защищены";
  public NAME_1 = "Тохир Тухтаров";
  public NAME_2 = "Евгений Кузнецов";
  public LINK_VIDEO = "https://www.youtube.com/embed/qJ0AG9OYvVA";
}

export default LanguageKeysRu;
/* eslint-enable */
