import {
  createStyles,
  Divider,
  Title,
  SimpleGrid,
  Text,
  Button,
  ThemeIcon,
  Grid,
  Col,
  Space,
  Mark,
} from "@mantine/core";
import React, { useMemo } from "react";
import { useViewportSize } from "@mantine/hooks";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";
import { Padding } from "../../components/ui/universal/PaddingWrapper";
// @ts-ignore
import video from "../../video4.mp4";
import { useT } from "../../i18n";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    position: "relative",
    minHeight: "100vh",
    boxSizing: "border-box",
    backgroundSize: "cover",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    backgroundColor: "rgba(0,0,0,0.65)",
  },

  video: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    top: 0,
    minWidth: "100vw",
    minHeight: "100vh",
    zIndex: -1,
  },

  features: {
    color: "white",
    padding: "0 32px",
  },
}));

const useStyles2 = createStyles((theme) => ({
  wrapper: {
    marginTop: -100,
  },

  title: {
    fontSize: "30px",
    color: "white",
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
  },

  faturesBox: {
    backdropFilter: "blur(5px)",
    padding: "12px 16px",
    backgroundColor: "rgba(255,255,255,0.12)",
    borderRadius: 8,
  },

  faturesBoxTitle: {
    fontSize: "24px",
    fontWeight: 500,
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      fontSize: "18px",
    },
  },
}));

export function FeaturesTitle() {
  const { classes } = useStyles2();
  const { isMobile } = useAdaptive();
  const t = useT();

  const features = [
    {
      icon: null,
      title: t("PAGE3_STR_1"),
    },
    {
      icon: null,
      title: t("PAGE3_STR_2"),
    },
    {
      icon: null,
      title: t("PAGE3_STR_3"),
    },
  ];

  const items = features.map((feature) => (
    <div className={classes.faturesBox} key={feature.title}>
      <div className={classes.faturesBoxTitle}>{feature.title}</div>
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <Grid gutter={isMobile ? 40 : 80}>
        <Col span={12} md={5}>
          <Space mt={30} />
          <Title className={classes.title} order={1} style={{ fontSize: isMobile ? 30 : 44 }}>
            {t("PAGE3_STR_4")}
          </Title>
          <Text size="xl" weight="bold">
            <Mark color="red" style={{ color: "black" }}>
              {t("PAGE3_STR_5")}
            </Mark>{" "}
            {t("PAGE3_STR_6")}
          </Text>
          <Space mt={isMobile ? 40 : 60} />
          <Title className={classes.title} order={1} style={{ fontSize: isMobile ? 30 : 44 }}>
            {t("PAGE3_STR_7")}
          </Title>
          <Text size="xl" weight="bold">
            <Mark color="blue" style={{ color: "black" }}>
              {t("PAGE3_STR_8")}
            </Mark>{" "}
            {t("PAGE3_STR_9")}{" "}
            <Mark color="blue" style={{ color: "black" }}>
              {t("PAGE3_STR_10")}
            </Mark>{" "}
            {t("PAGE3_STR_11")}
          </Text>
        </Col>
        <Col span={12} md={7}>
          <SimpleGrid cols={1} spacing={30} breakpoints={[{ maxWidth: "md", cols: 1 }]}>
            {items}
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}

export const Screen3 = () => {
  const { classes } = useStyles();
  const { isMobile } = useAdaptive();
  const { width, height } = useViewportSize();
  const t = useT();
  const heightHeading = 120;

  const heightItem = useMemo(() => height / 4 - heightHeading / 4, [height]);

  return (
    <div className={classes.wrapper}>
      <video className={classes.video} autoPlay muted loop>
        <source src={video} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className="no_select" style={{ width: "100%", height: `${heightHeading}px`, flex: 1 }}>
        <Padding vertical={4} horizontal={isMobile ? 1 : 4}>
          <Divider
            my="xs"
            variant="solid"
            size={2}
            color="gray"
            label={
              <Title order={isMobile ? 3 : 1} color="white" ta="center">
                {t("PAGE3_TITLE")}
              </Title>
            }
            labelPosition="center"
          />
        </Padding>
      </div>
      <div className={classes.features} style={{ flex: 1 }}>
        <Padding vertical={4} horizontal={isMobile ? 1 : 4}>
          <FeaturesTitle />
        </Padding>
      </div>
    </div>
  );
};
