import { createStyles, Divider, Title } from "@mantine/core";
import React, { useMemo } from "react";
import { useViewportSize } from "@mantine/hooks";
import { Parallax } from "react-scroll-parallax";
import S1PNG from "shared/static/images/s1.png";
import S2PNG from "shared/static/images/s2.png";
import S3PNG from "shared/static/images/s3.png";
import S4PNG from "shared/static/images/s4.png";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";
import { Padding } from "../../components/ui/universal/PaddingWrapper";
// @ts-ignore
import video from "../../video2.mp4";
import { useT } from "../../i18n";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    position: "relative",
    minHeight: "100vh",
    boxSizing: "border-box",
    backgroundSize: "cover",
    overflow: "hidden",
    userSelect: "none",
  },

  video: {
    position: "absolute",
    right: 0,
    bottom: 0,
    left: 0,
    top: 0,
    minWidth: "100vw",
    minHeight: "100vh",
    zIndex: -1,
  },

  item: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "stretch",
    padding: "8px 32px",
  },
  itemBox: {
    "flex": 1,
    "display": "flex",
    "color": "white",
    "boxSizing": "border-box",
    "border": "1px solid rgba(255,255,255,0)",
    "borderWidth": "1px 0",
    "backdropFilter": "blur(5px)",
    "& .counter": {
      alignItems: "stretch",
      fontSize: "80px",
      padding: "0 36px",
      fontWeight: 800,
      margin: "auto 0 auto 0",
      [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
        display: "none",
      },
    },
    "& .description": {
      flex: 2,
      fontSize: "24px",
      margin: "auto 0 auto 0",
      [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
        fontSize: "18px",
      },
    },
    "& .img": {
      "flex": 1,
      "display": "flex",
      "& img": {
        margin: "auto",
      },
    },
  },
}));

export const Screen2 = () => {
  const { classes } = useStyles();
  const { isMobile } = useAdaptive();
  const { width, height } = useViewportSize();
  const t = useT();
  const heightHeading = 120;

  const heightItem = useMemo(() => height / 4 - heightHeading / 4, [height]);

  return (
    <div className={classes.wrapper}>
      <video className={classes.video} autoPlay muted loop>
        <source src={video} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div className="no_select" style={{ width: "100%", height: `${heightHeading}px` }}>
        <Padding vertical={3} horizontal={isMobile ? 1 : 3}>
          <Divider
            my="xs"
            variant="solid"
            size={2}
            color="gray"
            label={
              <Title order={isMobile ? 3 : 1} color="white" ta="center">
                {!isMobile ? t("PAGE2_TYPES") : t("PAGE2_TYPES_MOBILE")}
              </Title>
            }
            labelPosition="center"
          />
        </Padding>
      </div>
      <div>
        <Parallax
          startScroll={0}
          endScroll={height - heightItem}
          translateX={[`-${width}px`, `${0}px`]}
          easing="easeInQuad">
          <div className={classes.item} style={{ height: `${heightItem}px` }}>
            <div className={classes.itemBox} style={{ borderTop: 0 }}>
              <div className="counter">1</div>
              <div className="description">{t("PAGE2_TYPE_1")}</div>
              <div className="img">
                <img src={S1PNG} alt="" />
              </div>
            </div>
          </div>
        </Parallax>
        <Parallax
          startScroll={heightItem}
          endScroll={height - heightItem / 2}
          translateX={[`-${width}px`, `${0}px`]}
          easing="easeInQuad">
          <div className={classes.item} style={{ height: `${heightItem}px` }}>
            <div className={classes.itemBox}>
              <div className="counter">2</div>
              <div className="description">{t("PAGE2_TYPE_2")}</div>
              <div className="img">
                <img src={S2PNG} alt="" />
              </div>
            </div>
          </div>
        </Parallax>
        <Parallax
          startScroll={heightItem * 2}
          endScroll={height - heightItem / 4}
          translateX={[`-${width}px`, `${0}px`]}
          easing="easeInQuad">
          <div className={classes.item} style={{ height: `${heightItem}px` }}>
            <div className={classes.itemBox}>
              <div className="counter">3</div>
              <div className="description">{t("PAGE2_TYPE_3")}</div>
              <div className="img">
                <img src={S3PNG} alt="" />
              </div>
            </div>
          </div>
        </Parallax>
        <Parallax
          startScroll={heightItem * 3}
          endScroll={height}
          translateX={[`-${width}px`, `${0}px`]}
          easing="easeInQuad">
          <div className={classes.item} style={{ height: `${heightItem}px`, borderBottom: 0 }}>
            <div className={classes.itemBox} style={{ borderBottom: 0 }}>
              <div className="counter">4</div>
              <div className="description">{t("PAGE2_TYPE_4")}</div>
              <div className="img">
                <img src={S4PNG} alt="" />
              </div>
            </div>
          </div>
        </Parallax>
      </div>
    </div>
  );
};
