import { createStyles, Divider, Title, Space } from "@mantine/core";
import React from "react";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";
import BG7 from "shared/static/images/bg7.jpg";
import { CarouselProjects } from "shared/features/Carousel";
import { Padding } from "../../components/ui/universal/PaddingWrapper";
import { useT } from "../../i18n";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    position: "relative",
    boxSizing: "border-box",
    minHeight: "100vh",
    backgroundSize: "1920px",
    backgroundRepeat: "repeat",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    paddingTop: "50px",
    backgroundColor: "rgba(0,0,0,0.65)",
    justifyContent: "stretch",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      minHeight: "auto",
    },
  },
}));

export const Screen6 = () => {
  const { classes } = useStyles();
  const { isMobile } = useAdaptive();
  const t = useT();

  return (
    <div className={classes.wrapper} style={isMobile ? {} : { backgroundImage: `url(${BG7})` }}>
      <div className="no_select" style={{ width: "100%" }}>
        <Divider
          my="xs"
          variant="solid"
          size={2}
          color="gray"
          label={
            <Title order={isMobile ? 3 : 1} color="white" ta="center">
              {t("PAGE6_STR_1")}
            </Title>
          }
          labelPosition="center"
        />
      </div>
      <div style={{ flex: 1, display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Padding horizontal={3} vertical={3}>
          <Title order={isMobile ? 3 : 2} color="white" ta="center" weight={500}>
            {t("PAGE6_STR_2")}
          </Title>
        </Padding>
        <div className="display_flex" style={{ flex: "1" }}>
          <div className="margin_auto w-100">
            <CarouselProjects />
          </div>
        </div>
      </div>
      <Space mt={50} />
    </div>
  );
};
