import { createStyles, Title, Space, Grid } from "@mantine/core";
import React from "react";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";
import BG2 from "shared/static/images/bg7.jpg";
import S6JPG from "shared/static/images/s6.jpg";
import { Padding } from "../../components/ui/universal/PaddingWrapper";
import { useT } from "../../i18n";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    position: "relative",
    boxSizing: "border-box",
    minHeight: "100vh",
    backgroundSize: "1920px",
    backgroundRepeat: "repeat",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    paddingTop: "50px",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      paddingTop: 0,
    },
  },
}));

export const Screen7 = () => {
  const { classes } = useStyles();
  const { isMobile } = useAdaptive();
  const t = useT();

  return (
    <div className={classes.wrapper} style={isMobile ? {} : { backgroundImage: `url(${BG2})` }}>
      <div>
        <Padding horizontal={4} vertical={3}>
          <Title order={isMobile ? 3 : 1} color="white" ta="left">
            {t("PAGE7_STR_1")}
          </Title>
          <Space mt={30} />
          <h2>{t("PAGE7_STR_2")}</h2>
        </Padding>
        <Padding horizontal={4} vertical={3}>
          <Grid columns={isMobile ? 1 : 2} gutter={50}>
            <Grid.Col span={1}>
              <img src={S6JPG} alt="s6jpg" style={isMobile ? {} : { borderRadius: "24px" }} />
            </Grid.Col>
            <Grid.Col span={1} style={{ alignItems: "stretch", display: "flex" }}>
              <div className="margin_auto">
                <div>
                  <h3>{t("PAGE7_STR_3")}</h3>
                </div>
                <Space mt={24} />
                <div>
                  <h3>{t("PAGE7_STR_4")}</h3>
                </div>
              </div>
            </Grid.Col>
          </Grid>
        </Padding>
      </div>
      <Space mt={50} />
    </div>
  );
};
