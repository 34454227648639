import { createStyles, Divider, Title, SimpleGrid, Grid, Col, Space } from "@mantine/core";
import React from "react";
import { ADAPTIVE_BREAKPOINT, useAdaptive } from "shared/hooks";
import classNames from "classnames";
import BG1 from "shared/static/images/bg6.jpg";
import { useT } from "../../i18n";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    position: "relative",
    minHeight: "100vh",
    boxSizing: "border-box",
    backgroundSize: "1920px",
    backgroundRepeat: "repeat",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    paddingTop: "50px",
    backgroundColor: "rgba(0,0,0,0.65)",
  },

  features: {
    color: "white",
    padding: "0 32px 0",
    margin: "auto",
  },
}));

const useStyles2 = createStyles((theme) => ({
  wrapper: {},

  title: {
    fontSize: "30px",
    color: "white",
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
  },

  faturesBox: {
    backdropFilter: "blur(8px)",
    padding: "12px 16px 18px",
    backgroundColor: "rgba(0,0,0,0.12)",
    boxShadow: "0 0 300px 1px black",
    borderRadius: "12px",
  },

  faturesBoxColorized: {
    background: "rgba(255,255,255,0.1)",
  },

  faturesBoxTitle: {
    fontSize: "30px",
    fontWeight: 500,
    color: "white",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      fontSize: "18px",
    },
  },
  faturesBoxDescription: {
    fontSize: "20px",
    letterSpacing: "1px",
    fontWeight: 300,
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      fontSize: "18px",
    },
  },
}));

export function FeaturesTitle() {
  const { classes } = useStyles2();
  const t = useT();

  const features = [
    {
      icon: null,
      title: t("PAGE4_STR_2"),
      description: t("PAGE4_STR_3"),
    },
    {
      icon: null,
      title: t("PAGE4_STR_4"),
      description: t("PAGE4_STR_5"),
    },
    {
      icon: null,
      title: t("PAGE4_STR_6"),
      description: t("PAGE4_STR_7"),
    },
    {
      icon: null,
      title: t("PAGE4_STR_8"),
      description: t("PAGE4_STR_9"),
    },
    {
      icon: null,
      title: t("PAGE4_STR_10"),
      description: t("PAGE4_STR_11"),
      colorized: true,
    },
    {
      icon: null,
      title: t("PAGE4_STR_12"),
      description: t("PAGE4_STR_13"),
      colorized: true,
    },
    {
      icon: null,
      title: t("PAGE4_STR_14"),
      description: t("PAGE4_STR_15"),
      colorized: true,
    },
    {
      icon: null,
      title: t("PAGE4_STR_16"),
      description: t("PAGE4_STR_17"),
      colorized: true,
    },
    {
      icon: null,
      title: t("PAGE4_STR_18"),
      description: t("PAGE4_STR_19"),
      colorized: true,
    },
  ];

  const items = features.map((feature) => (
    <div
      className={classNames(classes.faturesBox, {
        [classes.faturesBoxColorized]: !!feature?.colorized,
      })}
      key={feature.title}>
      <div className={classes.faturesBoxTitle}>{feature.title}</div>
      <div className={classes.faturesBoxDescription}>{feature.description}</div>
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <Grid>
        <Col span={12}>
          <SimpleGrid cols={3} spacing={24} breakpoints={[{ maxWidth: ADAPTIVE_BREAKPOINT, cols: 1 }]}>
            {items}
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}

export const Screen4 = () => {
  const { classes } = useStyles();
  const { isMobile } = useAdaptive();
  const t = useT();

  return (
    <div className={classes.wrapper} style={{ backgroundImage: `url(${BG1})` }}>
      <div className="no_select" style={{ width: "100%" }}>
        <Divider
          my="xs"
          variant="solid"
          size={2}
          color="gray"
          label={
            <Title order={isMobile ? 3 : 1} color="white" ta="center">
              {t("PAGE4_STR_1")}
            </Title>
          }
          labelPosition="center"
        />
      </div>
      <Space mt={50} />
      <div className={classes.features}>
        <FeaturesTitle />
      </div>
      <Space mt={50} />
    </div>
  );
};
