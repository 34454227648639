import { createStyles } from "@mantine/core";
import { ADAPTIVE_BREAKPOINT } from "shared/hooks";
import BG8 from "shared/static/images/bg7.jpg";
import { useT } from "../../i18n";

const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    backgroundSize: "1920px",
    backgroundRepeat: "repeat",
    backgroundColor: "gray",
    alignItems: "stretch",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      minHeight: "auto",
    },
  },
  videoWrapper: {
    "margin": "10vh auto",
    "maxWidth": "80%",
    "maxHeight": "80vh",
    "boxSizing": "border-box",
    "backgroundColor": "black",
    "position": "relative",
    "flex": 1,
    "display": "flex",
    "alignItems": "stretch",
    [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
      maxWidth: "100%",
      maxHeight: "auto",
      margin: 0,
    },
    "& iframe": {
      width: "100%",
      height: "auto",
      boxShadow: "0 0 350px 0 rgba(255,255,255, 0.15)",
      [`@media (max-width: ${ADAPTIVE_BREAKPOINT}px)`]: {
        height: 300,
      },
    },
  },
}));

export const ScreenVideo = () => {
  const { classes } = useStyles();
  const t = useT();
  return (
    <div className={classes.wrapper} style={{ backgroundImage: `url(${BG8})` }}>
      <div className={classes.videoWrapper}>
        <iframe
          src={t("LINK_VIDEO")}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; controls; modestbranding; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </div>
  );
};
